import * as React from "react"
import { Link } from "gatsby" 
import { StaticImage } from "gatsby-plugin-image" 
import Seo from "../components/seo"

const pageStyles = {
  color: "#000000",
  padding: "10px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const imageStyles = {
    padding: "10px",  
  }
const linkStyles = {
    color: "#0000ff",
    padding: "10px",
    fontSize: "1.3em",
    fontWeight: "bold", 
  }
const headingStyles = {
    fontWeight: "600",
    lineHeight: "1.375",
    fontSize: "2.75em",
    color:"#000000",
    marginTop: 0,
    marginBottom: 20,
    maxWidth: 320,
    margin: 0,
    padding:10,   
   }
const paragraphStyles = {
  marginBottom: 48,
  padding:10, 
}
const NotFoundPage = () => {
  return (
    <main style={pageStyles}>
        <div style={imageStyles}>
     <StaticImage src="../images/404.png" width={300} quality={95} formats={["png"]} alt="Русдосуг" /></div>
      <h1 style={headingStyles}>Ошибка 404</h1>
     <br></br>
      <p style={paragraphStyles}>
      Неправильно набран адрес или такой страницы на сайте больше не существует!</p>
      <ul style={linkStyles}>
		<li><Link to="/">Главная страница</Link></li>
	 </ul>
    </main>
  )
}
export const Head = () => (
    <Seo title="404 - Ничего не найдено!"
      description="Досуг-сз.рф: 404 - Ничего не найдено!">
      <meta name="robots" content="noindex,nofollow"/>
    </Seo>
  )
export default NotFoundPage